import { httpClient } from './httpClient.js';

const
    LISTAPIKEYS = 'dashboard/apikeys',
    REGENERATEAPIKEYS = 'dashboard/apikeys/regenerate';

const listApiKeys = () => httpClient.dashboardService.get(LISTAPIKEYS);
const regenerateApiKey = (name) => httpClient.dashboardService.put(REGENERATEAPIKEYS, { "name": name });

export {
    listApiKeys,
    regenerateApiKey
};
