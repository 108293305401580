<template>
  <div>
    <CHeader light class="border-0 c-sticky-top">
      <nav
        class="navbar navbar-expand-lg navbar-light"
        style="padding-left: 11%; width: 100%"
      >
        <CRow class="login-header">
          <CIcon
            class="c-sidebar-brand-full mb-3"
            name="logo"
            size="custom-size"
            :height="45"
            viewBox="0 0 550 148"
            style="padding-right: 100px"
          />
        </CRow>
      </nav>
    </CHeader>
    <div class="signup-main">
      <CContainer class="container-form">
        <div class="left">
          <h1 class="section-title">
            {{ $t("LOGIN.HEADER_MSG") }}
          </h1>
          <img
            src="../../assets/img/img_hero.png"
            alt="default.svg"
            height="336px"
            width="500px"
          />
        </div>
        <div class="right">
          <form class="form">
            <CAlert color="primary" v-if="showPrimaryInfoAlert">
              <CIcon name="cil-check-circle" />&nbsp;&nbsp;{{ this.alertMsg }}
            </CAlert>
            <CAlert color="danger" v-if="showPrimaryErrorAlert">
              <CIcon name="cil-warning" />&nbsp;&nbsp;{{ this.alertMsg }}
            </CAlert>
            <div class="fullrow">
              <CInput
                :label="$t('LOGIN.LABEL_EMAIL')"
                type="email"
                :placeholder="$t('LOGIN.PLACEHOLDER_EMAIL')"
                class="col-12 p-0"
                v-model="username"
                required
                valid-feedback
                :invalid-feedback="$t('LOGIN.VALIDATION_EMAIL')"
                :is-valid="validateEmail"
              >
              </CInput>
            </div>
            <div class="fullrow">
              <CInput
                :label="$t('LOGIN.LABEL_PASSWORD')"
                :placeholder="$t('LOGIN.PLACEHOLDER_PASSWORD')"
                type="password"
                class="col-12 p-0 mb-0"
                v-model="password"
                required
                valid-feedback
                :invalid-feedback="$t('LOGIN.VALIDATION_PASSWORD')"
                :is-valid="validator"
              >
              </CInput>
            </div>
            <div class="fullrow mb-3">
              <CButton
                class="forgot-password-btn"
                id="forgotPassword"
                @click="forgotPassword"
              >
                {{ $t("LOGIN.FORGOT_PASSWORD_BUTTON") }}
              </CButton>
            </div>
            <div class="rowcta mt-3">
              <CButton
                color="durianprimary"
                @click="login"
                class="col-6 col-xl-6 px-4 mb-3 bigbutton"
              >
                {{ $t("LOGIN.LOGIN_BUTTON") }}
              </CButton>
              <p>
                <span>{{ $t("LOGIN.PLACEHOLDER_3") }}</span>
                <a @click="goTo('/signup')">
                  {{ $t("LOGIN.REGISTER_BUTTON") }}
                </a>
              </p>
            </div>
          </form>
        </div>
      </CContainer>
    </div>
  </div>
</template>

<script>
import * as types from "@/store/mutation-types";
import authenticateUser from "@/api/login.api.js";
import { listApiKeys } from "@/api/apikeys.api.js";

const ERR_FIELD = "field";
const ERR_EMAIL_NOT_VERIFIED = "email_verified";
const ERR_LOGIN_ATTEMPTS = "login_attempts";
const ERR_ACCOUNT_LOCKED = "locked";
const IS_LIVE_IDENTIFIER = "dp_live";

export default {
  name: "Login",
  data() {
    return {
      username: null,
      password: null,
      showPrimaryErrorAlert: false,
      showPrimaryInfoAlert: false,
      alertMsg: "",
    };
  },
  methods: {
    login() {
      if (
        !this.validateEmail(this.username) ||
        !this.validator(this.password)
      ) {
        this.showToaster(this.$t("COMMON.REQ_FIELDS"));
        return;
      }

      authenticateUser(this.username, this.password)
        .then((response) => {
          this.$store.commit(
            types.setMerchantID,
            response.data.data.merchant_id
          );
          this.$store.commit(
            types.setToken,
            "bearer " + response.data.data.token
          );
          this.$store.commit(types.setUserRole, response.data.data.role_name);
          this.$store.commit(types.setMerchantName, response.data.data.name);
          this.$store.commit(types.setIsMerchantVerified, true);
          this.$store.commit(types.setEmailID, response.data.data.email);
          this.$store.commit(types.setPageViews, response.data.data.permissions);
          this.$store.commit(types.setIsDisbursementEnabled, response.data.data.feature_config.disbursements.is_enabled);
          this.$store.commit(types.setIsRefundEnabled, response.data.data.feature_config.refund.is_enabled);
          listApiKeys().then((response) => {
            const err = this.verifyHttpResponse(response);
            if (err === false) {
              let apikeys = response.data.data;
              let flag = 0;
              apikeys.forEach((key) => {
                if (key.secret_key.indexOf(IS_LIVE_IDENTIFIER) !== -1) {
                  flag = 1;
                }
              });
              if (flag === 0) {
                // merchant is not live as live API key not found
                this.$store.commit(types.setIsMerchantActivated, false);
                this.$store.commit(types.toggleSandboxMode, true);
              } else {
                this.$store.commit(types.setIsMerchantActivated, true);
                this.$store.commit(types.toggleSandboxMode, false);
              }
            }
          });

          this.$router.push("/home");
        })
        .catch((e) => {
          console.log(e)
          if (
            e &&
            e.response &&
            e.response.data &&
            e.response.data.status !== 200
          ) {
            let errorArr = e.response.data.errors
              ? e.response.data.errors[0]
              : {};
            if (errorArr.hasOwnProperty(ERR_FIELD)) {
              let errorField = errorArr[ERR_FIELD];
              if (errorField === ERR_EMAIL_NOT_VERIFIED) {
                this.$router.push("/email_not_verified");
                return;
              }
              if (errorField === ERR_ACCOUNT_LOCKED) {
                this.alertMsg = errorArr["message"];
                this.showPrimaryErrorAlert = true;
                return;
              }
              if (errorField === ERR_LOGIN_ATTEMPTS) {
                let message = this.$t("LOGIN.ERROR_LOGIN_ATTEMPS_MSG", {
                  attempts: errorArr["message"],
                });
                this.alertMsg = message;
                this.showPrimaryErrorAlert = true;
                return;
              }
              if (errorArr["field"] === "") {
                this.alertMsg = errorArr["message"];
                this.showPrimaryErrorAlert = true;
                return;
              }
              if (e.response.data.error) {
                this.alertMsg = e.response.data.error;
                this.showPrimaryErrorAlert = true;
              }
            } else {
              this.alertMsg = this.$t("LOGIN.ERROR");
              this.showPrimaryErrorAlert = true;
            }
          } else {
            this.alertMsg = this.$t("LOGIN.ERROR");
            this.showPrimaryErrorAlert = true;
          }
          return;
        });
    },
    forgotPassword() {
      this.$router.push("/forgot_password");
    },
    validator(val) {
      if (val == undefined) {
        return "";
      }
      return val ? val.length >= 1 : false;
    },
    validateEmail(val) {
      if (val == undefined) {
        return "";
      }
      var pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (pattern.test(val)) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style scoped>
.login-title {
  font-family: Apercu;
}
.text-intro {
  font-family: Apercu;
  text-align: justify;
  text-justify: inter-word;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 2rem !important;
}
</style>